import React, { useMemo } from 'react';
import './TextInput.css';

export type TextInputProps = {
  id: string;
  label: string;
  required?: boolean;
  type?: 'text' | 'email' | 'password' | 'textarea';
};

function TextInput(props: TextInputProps) {
  const inputType = useMemo(() => props.type || 'text', [props.type]);
  const required = useMemo(() => props.required || false, [props.required]);

  const mappedInputProps: React.InputHTMLAttributes<unknown> = useMemo(
    () => ({
      id: props.id,
      'aria-label': props.label,
      className: 'text-input-element',
      required,
    }),
    [props.label, props.id],
  );

  return (
    <div className="text-input">
      <label htmlFor={props.id} className="text-small text-bold">
        {props.label}
        {props.required ? (
          <span className="text-input-required-label"> (required)</span>
        ) : null}
      </label>
      <div className="text-input-label-spacer" />
      {inputType === 'textarea' ? (
        <textarea {...mappedInputProps} />
      ) : (
        <input type={inputType} {...mappedInputProps} />
      )}
      <div className="spacing-tiny" />
    </div>
  );
}

export default TextInput;
