import React from 'react';

import './App.css';
import Body from './Body/Body';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { BrowserRouter } from 'react-router-dom';

// TODO 404 and error state
// TODO pakthq.com redirect/certs, check emails are still working
// TODO /user /activity page
// TODO Contact page
// TODO Content translation

export const COMPONENT_ID = 'app';

function App() {
  return (
    <div id={COMPONENT_ID} data-testid={COMPONENT_ID}>
      <BrowserRouter>
        <Header />
        <Body />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
