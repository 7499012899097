import React from 'react';
import PageTitle from '../../PageTitle/PageTitle';
import TextInput from '../../TextInput/TextInput';
import './Contact.css';

export const COMPONENT_ID = 'contact';

function Contact() {
  return (
    <>
      <PageTitle title="Contact Us" />
      <div id={COMPONENT_ID} data-testid={COMPONENT_ID}>
        <div className="contact-form-spacer" />
        <div className="content-container-large">
          <div className="contact-form-container">
            <h1 className="contact-form-title">Contact us</h1>
            <form className="contact-form">
              <div className="contact-form-name">
                <TextInput
                  id="contact-first-name"
                  label="First name"
                  required={true}
                />
                <TextInput
                  id="contact-last-name"
                  label="Last name"
                  required={true}
                />
              </div>
              <div>
                <TextInput
                  id="contact-email"
                  type="email"
                  label="Email"
                  required={true}
                />
              </div>
              <div>
                <TextInput
                  id="contact-message"
                  type="textarea"
                  label="Message"
                  required={true}
                />
              </div>
            </form>
          </div>
        </div>
        <div className="contact-form-spacer" />
      </div>
    </>
  );
}

export default Contact;
